import {Image, Button, Typography} from '../ui';
import err500 from '~/assets/images/Error500.png';
interface Props {
  errorMessage: string;
}

export function GenericError(error: Props) {
  const {errorMessage} = error;
  const heading = 'Error 500';
  const description1 = 'Something went wrong.'; // There are 2 descriptions because the line break could not be made using "\n".
  const description2 = 'Come back later or reload page.';

  // TODO hide error in prod?
  if (error) {
    console.error(errorMessage);
  }

  return (
    <div className="px-nav text-center mt-10 mb-12 md:my-36">
      <Typography variant="headline1">{heading}</Typography>
      <Image
        src={err500}
        alt="Error 500"
        className="w-[234px] h-[161px] md:w-[570px] md:h-[402px] mx-auto my-5"
      />
      <Typography variant="body1">{description1}</Typography>
      <Typography variant="body1" className="mb-5">
        {description2}
      </Typography>
      <Button className="w-full h-12 md:w-auto" variant="cool" to={'/'}>
        Return to main page
      </Button>
    </div>
  );
}
