import { Await, Link, NavLink, useFetcher, useNavigate } from '@remix-run/react';
import { MobileMenuAside, type LayoutProps } from './Layout';
import { DCLogoTypes, Logo } from './ui/Logo.component';
import { Fridge } from './Fridge.component';
import { User2, UserPlus2, X } from 'lucide-react';
import { Button, EcodriveButton, Image, Input, Separator, Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger, Typography } from './ui';
import { CartApiQueryFragment, HeaderQuery } from 'storefrontapi.generated';
import { CartBadge } from './Menu.component';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { ProductSearch } from '~/models';
import { TooltipPortal } from '@radix-ui/react-tooltip';

type HeaderProps = Pick<
  LayoutProps,
  | 'header'
  | 'cart'
  | 'isLoggedIn'
  | 'categories'
  | 'className'
  | 'openCart'
  | 'setOpenCart'
  | 'openMenu'
  | 'setOpenMenu'
  | 'mainSearch'
  | 'setMainSearch'
  | 'socialNetwork'
  | 'purchaseForFreeShipping'
>;

type Viewport = 'desktop' | 'mobile';


const classSellerMenu = ''
  //'font-manrope sm:text-[16px] sm:leading-[24px] text-[16px] leading-[24px] self-center py-auto';

const menuCollection = [
  {
    name: 'Daily Deals',
    to: '/#DailyDeals',
    reloadDocument: false
  },
  {
    name: 'Best sellers',
    to: '/bestsellers',
    reloadDocument: true
  },
  {
    name: 'Storefronts',
    to: '/storefront',
    reloadDocument: true
  },
  {
    name: 'Help Center',
    to: '/helpcenter',
    reloadDocument: true
  },
  /*TODO: This link was disabled by Client*/
  // {
  //   name: 'How it works',
  //   to: '/howitworks',
  //   reloadDocument: false
  // }
]

export function Header({
  header,
  isLoggedIn,
  cart,
  categories,
  openCart,
  setOpenCart,
  openMenu,
  setOpenMenu,
  mainSearch,
  setMainSearch,
  purchaseForFreeShipping
}: HeaderProps) {
  const fetcher = useFetcher<ProductSearch>()
  const [searchModel, setSearchModel] = useState<ProductSearch>({} as ProductSearch);
  const [mainSearchHasFocus, setMainSearchHasFocus] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const mainSearchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  let searchTimeout: any = null;
  const onSearchChange = (searchValue: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      if (searchValue === '') {
        return;
      }
      setSearching(true);
      fetcher.data = { products: [] } as ProductSearch;
      setSearchModel({ products: [] } as ProductSearch);
      const urlWithFilters = `/searchProducts?q=${searchValue}`;
      fetcher.load(urlWithFilters);
    }, 200);
  }

  useEffect(() => {
    setSearching(false);
    setSearchModel(fetcher.data || {} as ProductSearch)
  }, [fetcher.data]);

  const triggerChange = (value: string) => {
    setMainSearch(value);

    if (value !== '') {
      setSearching(true);
      onSearchChange(value);
    }
    else {
      fetcher.data = { products: [] } as ProductSearch;
      setSearchModel({ products: [] } as ProductSearch);
    }
  }

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const onHover = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const textElement = event.currentTarget.firstChild as HTMLElement;
    setShowTooltip(textElement.scrollWidth > textElement.clientWidth);
  }
  const onLeave = () => setShowTooltip(false);

  const handleOnFocus = () => {
    setMainSearchHasFocus(true);
    document.getElementsByTagName("html")[0].style.scrollPaddingTop="0rem";
  }

  const handleOnBlur= () => {
    document.getElementsByTagName("html")[0].style.scrollPaddingTop="10rem";
    setTimeout(() => setMainSearchHasFocus(false), 200);
  }

  const handleCloseFreeShippingDiv = () => {
    document.getElementById('divFreeShipping')?.classList.add("hidden");
  }

  return (
      <header className="header flex flex-col w-full gap-2 bg-slate-300 shadow-lg shadow-slate-300/50">
        <div id='divFreeShipping' className='h-6 bg-yellow-500 flex flex-row w-full items-center place-content-center py-4 pr-1'>
          <Typography variant="label" className='text-black font-semibold md:text-[14px] !text-[13px]'>Always free shipping! Minimum order total ${purchaseForFreeShipping}</Typography>
          <X
            className="absolute top-2 md:right-4 right-2 cursor-pointer h-4 w-4"
            onClick={handleCloseFreeShippingDiv}
          />
        </div>
        <div className='items-end px-nav py-4 w-full'>
          <div className="flex w-full items-center">
            <div className='flex flex-row items-center'>
              <Link prefetch="none" to="/" className={"hidden xl:block border-none w-[210px]"} reloadDocument>
                <Logo type={DCLogoTypes.FullColorHorizontal} size={210} />
              </Link>
              <Link prefetch="none" to="/" className={"xl:hidden block border-none mx-0"} style={{ width: 40 }} reloadDocument>
                <Logo type={DCLogoTypes.IconFullColor} size={50} className='max-w-full h-auto' />
              </Link>
            </div>
            <div className='relative pr-2 pl-8 xs:pr-4 sm:pr-6 md:pr-8 xl:pr-12 2xl:pr-16 flex-grow pt-1'>
              <Input
                ref={mainSearchRef}
                variant="search"
                type="search"
                value={mainSearch}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                onChange={(e) => { triggerChange(e.target.value) }}
                onKeyDown={(e) => {
                  const regex = /^[a-zA-Z0-9\s]*$/;
                  const charCode = e.key;
                  if(charCode === "Enter") {
                    navigate(`/categories?search=${(e.target as HTMLInputElement).value}`, { preventScrollReset: true });

                    mainSearchRef.current?.blur();
                  }
                  if (!regex.test(charCode)) {
                    e.preventDefault(); // Prevent entering special characters
                    return;
                  }              
                }}
                maxLength={50}
                className="w-full font-manrope font-normal sm:text-[18px] sm:leading-[20px] text-[14px] leading-[18px] max-w-full h-[48px]"
                placeholder='Search' />
              <section
                className={`overflow-x-hidden left-1/2 -translate-x-1/2 p-0 absolute top-14 z-50 min-w-[200px] w-[92.5%] xl:w-[94.5%] 2xl:w-[96%] 3xl:w-[97%] max-h-96 overflow-y-auto transition-opacity rounded-b-lg rounded-br-lg bg-white shadow-sm shadow-ice_blue shadow-border border-2 border-silver/30 ${mainSearchHasFocus && 
                  (mainSearchRef.current?.value || "") !== "" ? 'opacity-100' : 'opacity-0 z-[-1]'}`}>
                <TooltipProvider>
                  {fetcher.state === 'idle' && searchModel.products && searchModel.products.length > 0 && <div className={`w-full pt-4 transition-transform duration-500 -translate-y-full ${mainSearchHasFocus && 
                    (mainSearchRef.current?.value || "") !== "" ? 'translate-y-0' : ''}`}>
                    {searchModel.products.map((result, index) => (
                      <Link
                        className='max-w-full'
                        reloadDocument
                        to={`/products/${result.handle}`}
                        onClick={() => setMainSearchHasFocus(false)}
                        key={index}
                        onMouseOut={onLeave}
                        onMouseEnter={onHover}>
                        <div className='hover:bg-ice_blue/10'>
                          <Tooltip>
                            <TooltipTrigger className='max-w-full' asChild>
                              <Typography
                                variant='body2'
                                className="cursor-pointer text-ellipsis whitespace-nowrap overflow-hidden max-w-full px-4 pt-2">
                                {result.title}
                              </Typography>
                            </TooltipTrigger>
                            <TooltipPortal>
                              <TooltipContent side='bottom' className={`bg-black text-white ${!showTooltip ? 'hidden' : ''} text-xs`}>
                                {result.title}
                                <TooltipArrow />
                              </TooltipContent>
                            </TooltipPortal>
                          </Tooltip>
                          <Typography type='p' variant='overline' className='m-0 px-5 bottom-1 text-ellipsis whitespace-nowrap overflow-hidden max-w-full block'>{result.vendor}</Typography>
                          {<div className="px-4">
                            <Separator className="mt-1 bg-silver/40 h-0.5 rounded-lg" />
                          </div>}
                        </div>
                      </Link>
                    )
                    )}
                  </div>}
                </TooltipProvider>
                {fetcher.state === 'loading' && <div className="h-18">
                  <Typography variant='link' className="p-4 my-0">Searching ...</Typography>
                </div>}
                {!searching && searchModel.products && searchModel.products.length === 0 && <div className="h-18">
                  <Typography variant='link' className="p-4 my-0">No products to show</Typography>
                </div>}
              </section>
            </div>
            <HeaderCtas
              header={header}
              isLoggedIn={isLoggedIn}
              cart={cart}
              className="hidden md:flex items-end flex-grow"
              setOpenMenu={setOpenMenu}
              setOpenCart={setOpenCart}
              openCart={openCart}
              openMenu={openMenu}
            />
          </div>
          <div className='w-full justify-between hidden md:flex pl-0 xl:pl-[242px] pt-2'>
            {/* <div className='w-[40px] xl:w-[210px] mr-8'> */}
              {/* <Link prefetch="none" to="/" className={"hidden xl:block border-none w-[210px]"} reloadDocument>
                  <Logo type={DCLogoTypes.FullColorHorizontal} size={210} />
                </Link>
                <Link prefetch="none" to="/" className={"xl:hidden block border-none mx-0"} style={{ width: 40 }} reloadDocument>
                  <Logo type={DCLogoTypes.IconFullColor} size={50} className='max-w-full h-auto' />
                </Link> */}
            {/* </div> */}
            <div className='w-full contents gap-3.5 lg:gap-10 xl:gap-14  '>
              <Fridge categories={categories} />
              {menuCollection.map((menu) => (
                  <NavLink
                    key={menu.name}
                    to={menu.to}
                    /*TODO: this is the original class.*/
                    // className={({ isActive, isPending }) =>
                    //   `font-manrope sm:text-[16px] sm:leading-[24px] text-[16px] leading-[24px] self-center py-auto" ${isActive && menu.to !== '' ? "font-bold" : ""}`
                    // }
                    className={'font-manrope sm:text-[16px] sm:leading-[24px] text-[16px] leading-[24px] self-center py-auto whitespace-nowrap'}
                    reloadDocument={menu.reloadDocument} >
                    {menu.name}
                  </NavLink>
              ))}
            </div>
            <EcodriveButton mainText='1 ORDER = 1 TREE PLANTED'/>
          </div>
          <></>
        </div>
      </header>    
  );
}

export function HeaderMenu({
  menu,
  viewport,
  cart,
  className,
  isLoggedIn,
  openCart,
  setOpenCart,
  setOpenMenu,
  setOpenDeliveredMenu,
  isSellerMenu,
}: {
  menu: HeaderProps['header']['menu'];
  viewport: Viewport;
  cart: Promise<CartApiQueryFragment | null>;
  className?: string;
  isLoggedIn: boolean;
  openCart: boolean;
  setOpenCart: Function;
  setOpenMenu: Function;
  setOpenDeliveredMenu: Function;
  isSellerMenu: boolean;
}) {
  const classNameHeader = `header-menu-mobile`;

  function closeAside(event: React.MouseEvent<HTMLAnchorElement>) {

    if (viewport === 'mobile') {
      event.preventDefault();
      window.location.href = event.currentTarget.href;
    }
  }

  function closeAsideBecomeASeller(event: React.MouseEvent<HTMLAnchorElement>) {
    if (viewport === 'mobile') {
      setOpenMenu(false);
    }
  }

  const links = ["lnkHowItworksSM", "lnkAdvantagesSM", "lnkWhatCanYouSellSM", "lnkBrandCaseStudiesSM"];

  const handlerMenuEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    let id = (event.target as HTMLAnchorElement).id;

    links.map((lnkId) => {
      document.getElementById(lnkId)?.classList.remove(lnkId === id ? "font-normal" : "font-bold");
      document.getElementById(lnkId)?.classList.add(lnkId === id ? "font-bold" : "font-normal");
    });

  }

  return (
    <nav className={classNameHeader} role="navigation">
      {viewport === 'mobile' && !isSellerMenu && (
        <>
          {menuCollection.map((menu) => (
            <NavLink
              key={menu.name}
              to={menu.to}
              className={`header-menu-item ${className}`}
              reloadDocument>
              {menu.name}
            </NavLink>
          ))}
          {!isLoggedIn &&
            <NavLink prefetch="intent" to="/account/register" className={"group/edit"} onClick={() => setOpenMenu(false)}>
              <UserPlus2 size={20} className='inline mr-2' />Sign Up
            </NavLink>
          }
          <NavLink prefetch="intent" to="/account/login" className={"group/edit"} onClick={() => setOpenMenu(false)}>
            <User2 size={20} className='inline mr-2' />{isLoggedIn ? "Account" : "Sign in"}
          </NavLink>
          
          <Link
            to="/cart"
            className={`group/edit ${className} flex `}
            onClick={closeAside}
          >
            <Suspense fallback={<CartBadge count={0} className={className} />}>
              <Await resolve={cart}>
                {(cart) => {
                  if (!cart)
                    return <CartBadge count={0} className={className} />;
                  return (
                    <CartBadge
                      cost={cart.cost.totalAmount.amount}
                      count={cart.totalQuantity || 0}
                      className={className}
                    />
                  );
                }}
              </Await>
            </Suspense>
          </Link>
          <EcodriveButton className={"w-full"} mainText='1 ORDER = 1 TREE PLANTED' onClick={() => setOpenMenu(false)}/>
        </>
      )}
      {viewport === 'mobile' && isSellerMenu && (
        <>
          <Link
            className={`header-menu-item ${className}`}
            onClick={(event) => { handlerMenuEvent(event); closeAsideBecomeASeller(event); }}
            to={"#howItWorks"}
            reloadDocument
          >
            <Typography variant="body1" className={`${classSellerMenu}`} id='lnkHowItworksSM'>
              How it works
            </Typography>
          </Link>
          <Link
            className={`header-menu-item ${className}`}
            onClick={(event) => { handlerMenuEvent(event); closeAsideBecomeASeller(event); }}
            to={"#advantages"}
            reloadDocument
          >
            <Typography variant="body1" className={`${classSellerMenu}`} id='lnkAdvantagesSM'>Advantages</Typography>
          </Link>
          <Link
            className={`header-menu-item ${className}`}
            onClick={(event) => { handlerMenuEvent(event); closeAsideBecomeASeller(event); }}
            to={"#whatCanYouSell"}
          >
            <Typography variant="body1" className={`${classSellerMenu}`} id='lnkWhatCanYouSellSM'>What can you sell</Typography>
          </Link>
          <Link
            className={`header-menu-item ${className}`}
            onClick={(event) => { handlerMenuEvent(event); closeAsideBecomeASeller(event); }}
            to={"#brandCaseStudies"}
          >
            <Typography variant="body1" className={`${classSellerMenu}`} id='lnkBrandCaseStudiesSM'>Brand case studies</Typography>
          </Link>
          <Link to="/account" className={"group/edit"} onClick={closeAside}>
            <UserPlus2 size={20} className='inline mr-2' />Seller Sign Up
          </Link>
          <Link to="/account" className={"group/edit content-center items-center place-content-center"} onClick={closeAside}>
            <User2 size={20} className='inline mr-2 content-center items-center place-content-center' />Seller Log in
          </Link>
        </>
      )}
    </nav>
  );
}

export function HeaderCtas({
  isLoggedIn,
  cart,
  className,
  setOpenMenu,
  openMenu,
  setOpenCart,
  openCart,
  header,
}: {
  isLoggedIn: boolean;
  cart: Promise<CartApiQueryFragment | null>;
  className?: string;
  setOpenMenu: Function;
  openMenu: boolean;
  setOpenCart: Function;
  openCart: boolean;
  header: HeaderQuery;
}) {
  return (
    <nav className="flex flex-row gap-4 content-center items-center place-content-center" role="navigation">
      {!isLoggedIn &&
        <Button variant="cool" className={`px-4 mx-5 ${className}`} to='/account/register'>
          <Typography variant="body1" className='whitespace-nowrap'>Sign Up</Typography>
        </Button>
      }
      <NavLink prefetch="intent" to="/account" className={`group/edit ${className} flex-row mr-4`}>
        <User2 size={20} className='inline mr-1' />
        <Typography variant="body1" className='whitespace-nowrap'>{isLoggedIn ? "Account" : "Log in"}</Typography>
      </NavLink>
      <Link to="/cart" className={`group/edit ${className} flex`}>
        <Suspense fallback={<CartBadge count={0} className={className} />}>
          <Await resolve={cart}>
            {(cart) => {
              if (!cart) return <CartBadge count={0} className={className} />;
              return (
                <CartBadge
                  cost={cart.cost.totalAmount.amount}
                  count={cart.totalQuantity || 0}
                  className={className}
                />
              );
            }}
          </Await>
        </Suspense>
      </Link>
      <div className={'md:hidden block relative'}>
        <Suspense>
          <Await resolve={cart}>
            {(cart) => {
              if (!cart) return <></>;
              return (true
                ? <Typography variant="label" className='absolute rounded-full px-1.5 bg-amber-500  left-4'>{cart.totalQuantity || 0}</Typography>
                : undefined)
            }}
          </Await>
        </Suspense>
        <MobileMenuAside
          menu={header.menu}
          cart={cart}
          isLoggedIn={isLoggedIn}
          openCart={openCart}
          setOpenCart={setOpenCart}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          isSellerMenu={false}
        />
      </div>
    </nav>
  );
}


export function SellerHeader({
  header,
  isLoggedIn,
  cart,
  categories,
  openCart,
  setOpenCart,
  openMenu,
  setOpenMenu,
  socialNetwork
}: HeaderProps) {

  const links = ["lnkHowItworks", "lnkAdvantages", "lnkWhatCanYouSell", "lnkBrandCaseStudies"];

  const handlerMenuEvent = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    let id = (event.target as HTMLAnchorElement).id;

    links.map((lnkId) => {
      document.getElementById(lnkId)?.classList.remove(lnkId === id ? "font-normal" : "font-bold");
      document.getElementById(lnkId)?.classList.add(lnkId === id ? "font-bold" : "font-normal");
    });

  }

  return (
    <header className="header flex flex-col items-end px-nav gap-2 py-4 bg-slate-300 shadow-lg shadow-slate-300/50">
      <div className="flex w-full items-center">
        <div className='flex flex-row items-center'>
          <Link prefetch="intent" to="/" className={"hidden xl:block border-none w-[210px]"} reloadDocument>
            <Logo type={DCLogoTypes.FullColorHorizontal} size={210} />
          </Link>
          <Typography variant="headline5" className="hidden xl:block border-none ml-3 my-0">Sellers</Typography>
          <Link prefetch="intent" to="/" reloadDocument className={"xl:hidden block border-none mx-0"} style={{ width: 40 }} >
            <Logo type={DCLogoTypes.IconFullColor} size={50} className='max-w-full h-auto' />
          </Link>
        </div>
        <div className='w-[59vw] md-only:w-full lg-only:w-full justify-center hidden lg:flex 2xl:gap-7 gap-4'>
          <Link to="#howItWorks" onClick={(event) => { handlerMenuEvent(event); }} >
            <Typography variant="body1" className={`${classSellerMenu} `} id="lnkHowItworks">
              How it works
            </Typography>
          </Link>
          <Link to="#advantages" onClick={(event) => { handlerMenuEvent(event); }} >
            <Typography variant="body1" className={`${classSellerMenu}`} id="lnkAdvantages">Advantages</Typography>
          </Link>
          <Link to="#whatCanYouSell" onClick={(event) => { handlerMenuEvent(event); }} >
            <Typography variant="body1" className={`${classSellerMenu}`} id="lnkWhatCanYouSell">What can you sell</Typography>
          </Link>
          <Link to="#brandCaseStudies" onClick={(event) => { handlerMenuEvent(event); }} >
            <Typography variant="body1" className={`${classSellerMenu}`} id="lnkBrandCaseStudies">Brand case studies</Typography>
          </Link>
        </div>
        <div className='xl:flex flex-row items-end hidden'>
          <Button variant="cool" className={`2xl:px-10 xl:px-2 mr-4`} to={socialNetwork?.sellerLogin} >
            <Typography variant="button" className='whitespace-nowrap'>Seller Sign Up</Typography>
          </Button>
        </div>
        <div className='xl:flex flex-row items-end hidden'>
          <NavLink prefetch="intent" className={`group/edit flex flex-row content-center items-center place-content-center`} to={socialNetwork?.sellerLogin || ""}>
            <User2 size={20} className='inline mr-1' />
            <Typography variant="body1" className='whitespace-nowrap content-center items-center place-content-center'>Seller Log in</Typography>
          </NavLink>
        </div>
        <div className={'xl:hidden block items-end right-0 w-full lg-only:w-auto text-right'}>
          <MobileMenuAside
            menu={header.menu}
            cart={cart}
            isLoggedIn={isLoggedIn}
            openCart={openCart}
            setOpenCart={setOpenCart}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            isSellerMenu={true}
          />
        </div>
      </div>
    </header>
  );
};
