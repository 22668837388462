import {Await} from '@remix-run/react';
import {Suspense} from 'react';
import type {
  CartApiQueryFragment,
  FooterQuery,
  HeaderQuery,
} from 'storefrontapi.generated';
import {Aside} from '~/components/Aside';
import {Footer, SocialNetworkUrlModel} from '~/components/Footer';
import {Header, HeaderMenu, SellerHeader} from '~/components/Header';

import {
  PredictiveSearchForm,
  PredictiveSearchResults,
} from '~/components/Search';
import {CategoryWrapper} from '~/models';
import {DeliveredSideMenu} from './Menu.component';

export type LayoutProps = {
  cart: Promise<CartApiQueryFragment | null>;
  children?: React.ReactNode;
  footer: Promise<FooterQuery>;
  header: HeaderQuery;
  isLoggedIn: boolean;
  categories: CategoryWrapper;
  className?: string;
  openCart: boolean;
  setOpenCart: Function;
  openMenu: boolean;
  setOpenMenu: Function;
  mainSearch: string;
  setMainSearch: Function;
  storeHeader?: boolean | true;
  sellerHeader?: boolean | false;
  socialNetwork?: SocialNetworkUrlModel;
  purchaseForFreeShipping?: number;
};

export function Layout({
  cart,
  children = null,
  footer,
  header,
  isLoggedIn,
  categories,
  openCart,
  setOpenCart,
  openMenu,
  setOpenMenu,
  mainSearch,
  setMainSearch,
  storeHeader,
  sellerHeader,
  socialNetwork,
  purchaseForFreeShipping
}: LayoutProps) {
  return (
    <>
      <SearchAside />
      <div className="flex flex-col min-h-screen">
        {storeHeader && <Header
          header={header}
          cart={cart}
          isLoggedIn={isLoggedIn}
          categories={categories}
          openCart={openCart}
          setOpenCart={setOpenCart}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          mainSearch={mainSearch}
          setMainSearch={setMainSearch}
          purchaseForFreeShipping={purchaseForFreeShipping}
        />}
        {sellerHeader && <SellerHeader 
          header={header}
          cart={cart}
          isLoggedIn={isLoggedIn}
          categories={categories}
          openCart={openCart}
          setOpenCart={setOpenCart}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          mainSearch={mainSearch}
          setMainSearch={setMainSearch}
          socialNetwork={socialNetwork}
        />}
        <main className="flex-grow">{children}</main>
        <Suspense>
          <Await resolve={footer}>
            {(footer) => <Footer menu={footer.menu} socialNetwork={socialNetwork} />}
          </Await>
        </Suspense>
      </div>
    </>
  );
}

function SearchAside() {
  return (
    <Aside id="search-aside" heading="SEARCH">
      <div className="predictive-search">
        <br />
        <PredictiveSearchForm>
          {({fetchResults, inputRef}) => (
            <div>
              <input
                name="q"
                onChange={fetchResults}
                onFocus={fetchResults}
                placeholder="Search"
                ref={inputRef}
                type="search"
              />
              &nbsp;
              <button type="submit">Search</button>
            </div>
          )}
        </PredictiveSearchForm>
        <PredictiveSearchResults />
      </div>
    </Aside>
  );
}

export function MobileMenuAside({
  menu,
  cart,
  isLoggedIn,
  openCart,
  setOpenCart,
  openMenu,
  setOpenMenu,
  isSellerMenu
}: {
  menu: HeaderQuery['menu'];
  cart: Promise<CartApiQueryFragment | null>;
  isLoggedIn: boolean;
  openCart: boolean;
  setOpenCart: Function;
  openMenu: boolean;
  setOpenMenu: Function;
  isSellerMenu: boolean;
}) {
  return (
    <DeliveredSideMenu openSideMenu={openMenu} setOpenSideMenu={setOpenMenu}>
      <HeaderMenu
        menu={menu}
        viewport={'mobile'}
        className={'md:hidden block'}
        cart={cart}
        isLoggedIn={isLoggedIn}
        openCart={openCart}
        setOpenMenu={setOpenMenu}
        setOpenCart={setOpenCart}
        setOpenDeliveredMenu={setOpenMenu}
        isSellerMenu={isSellerMenu}
      />
    </DeliveredSideMenu>
  );
}
