import { Refrigerator, Store } from "lucide-react";
import { CategoryModel, CategoryWrapper } from "~/models";
import { DropdownMenu, DropdownMenuTrigger, Button, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuGroup, DropdownMenuSub, DropdownMenuSubTrigger, DropdownMenuPortal, DropdownMenuSubContent, DropdownMenuItem, Typography } from "./ui";
import { useResponsive } from "~/hooks";
import { Link } from "@remix-run/react";

interface FridgeProps {
    categories?: CategoryWrapper;
    openFridge?: boolean;
    setOpenFridge?: Function;
}

export const Fridge = (props: FridgeProps) => {
    const categories = props.categories;
    const responsive = useResponsive();

    const onCategorySelected = (categoryId: string) => {
        if(responsive.isLarge) {
            window.location.href = `/categories?subcategory=${categoryId}`;
            return;
        }        
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="cool" className="text-white hidden md:flex px-3 py-3 rounded-lg">
                    <Store size={30} className="block xl:hidden" fill="white" color="#00b5eb"/>
                    <Typography variant="button" className="hidden xl:block">Shop now!</Typography>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-100">
                <DropdownMenuLabel>Fridge</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    {categories &&
                        categories.categories?.map((item: CategoryModel) => (
                            <DropdownMenuSub key={item.id}>
                                <DropdownMenuSubTrigger>
                                    <Link to={`/categories/${item.handle}`} reloadDocument>{item.name}</Link>
                                </DropdownMenuSubTrigger>
                                <DropdownMenuPortal>
                                    <DropdownMenuSubContent>
                                        {item &&
                                            item.subCategories?.map((subcategory) => (
                                                <DropdownMenuItem key={subcategory.id}>
                                                    <Link to={`/categories/${subcategory.handle}`} reloadDocument>{subcategory.name}</Link>
                                                </DropdownMenuItem>
                                            ))}
                                    </DropdownMenuSubContent>
                                </DropdownMenuPortal>
                            </DropdownMenuSub>
                        ))}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};  