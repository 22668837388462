import {useMatches} from '@remix-run/react';
import type {ShopifyPageViewPayload} from '@shopify/hydrogen';
import { useMemo } from 'react';

interface PageAnalyticsOptions {
  hasUserConsent: boolean;
  shopId?: string;
  currency?: string;
  acceptedLanguage?: string;
}

export function usePageAnalytics(options: PageAnalyticsOptions) {
  const matches = useMatches();

  return useMemo(() => {
    const data: Record<string, unknown> = {};

    matches.forEach((event) => {
      const eventData = event?.data;
      if (eventData) {
        eventData['analytics'] && Object.assign(data, eventData['analytics']);

        const selectedLocale = eventData['selectedLocale'] || 'en';
        Object.assign(data, {
          currency: selectedLocale.currency,
          acceptedLanguage: selectedLocale.language,
        });
      }
    });

    return {
      ...data,
      ...options,
    } as unknown as ShopifyPageViewPayload;
  }, [matches]);
}
