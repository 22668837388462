import { DialogPortal, DialogOverlay, Close, DialogTitle } from "@radix-ui/react-dialog";
import { Await } from "@remix-run/react";
import { ShoppingCart, X, Menu } from "lucide-react";
import { Suspense } from "react";
import { CartApiQueryFragment } from "storefrontapi.generated";
import { CartMain } from "./Cart";
import { SideMenu, SideMenuTrigger, Button, SideMenuContent, SideMenuHeader, Typography } from "./ui";

interface CartSideMenuProps {
    cart: Promise<CartApiQueryFragment | null>
    openSideMenu?: boolean;
    setOpenSideMenu?: Function;
    setOpenDeliveredMenu: Function;
    className?: string;
    minimumPurchase: number;
}

export const CartSideMenu= (props: CartSideMenuProps) => {
    const cart = props.cart;
    const {openSideMenu, setOpenSideMenu, setOpenDeliveredMenu, className, minimumPurchase} = props;
    
    return(
        <SideMenu modal={true} open={openSideMenu}>
            <SideMenuTrigger onClick={()=> {
                setOpenDeliveredMenu && setOpenDeliveredMenu(false);
                setOpenSideMenu && setOpenSideMenu(!openSideMenu);
            }}>
                <Suspense fallback={<CartBadge count={0} className={className} />}>
                    <Await resolve={cart}>
                        {(cart) => {
                            if (!cart) return <CartBadge count={0} className={className}/>;
                            return <CartBadge cost={cart.cost.totalAmount.amount} count={cart.totalQuantity || 0} className={className}/>;
                        }}
                    </Await>
                </Suspense>
            </SideMenuTrigger>
            <DialogPortal>
                <DialogOverlay />
                <SideMenuContent onInteractOutside={()=>{setOpenSideMenu && setOpenSideMenu(false)}} className="fixed z-50 gap-4 bg-background p-4 pr-4 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 inset-y-0 right-0 h-full w-10/12 border-r data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm">
                    <Close asChild onClick={()=> {setOpenSideMenu && setOpenSideMenu(false)}} className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                        <X className="h-6 w-6" />
                    </Close>
                    <SideMenuHeader>
                        <DialogTitle className="text-left">Your Cart</DialogTitle>
                    </SideMenuHeader>                    
                    <Suspense fallback={<p>Loading cart ...</p>}>
                        <Await resolve={cart}>
                            {(cart) => {
                                return <CartMain cart={cart} layout="aside" minimumPurchase={minimumPurchase} />;
                            }}
                        </Await>
                    </Suspense>
                </SideMenuContent>
            </DialogPortal>
        </SideMenu>
    );
};

export function CartBadge({count, cost, className}: {count?: number; className?: string, cost?: string; }) {
    cost = parseFloat(cost || '0').toFixed(2);
    return (
        <div className={`group/edit ${className} relative flex`}>
        <ShoppingCart  size={20} className='inline mr-2' />
        {!!count && <Typography variant="body1" className='absolute rounded-full px-1.5 bg-amber-500 -top-2 left-3'>{count.toString()}</Typography>}
        {!!cost && cost !== '0.0' &&<Typography variant="body1" className='whitespace-nowrap ml-1'>{`$${cost}`}</Typography>}
        </div>
    );
}

interface DeliveredSideMenuProps {
    openSideMenu?: boolean;
    setOpenSideMenu?: Function;
    children?: React.ReactNode;
}

export const DeliveredSideMenu= (props: DeliveredSideMenuProps) => {
    const {openSideMenu, setOpenSideMenu} = props;
    
    return(
        <SideMenu modal={true} open={openSideMenu}>
            <SideMenuTrigger asChild onClick={()=> {setOpenSideMenu && setOpenSideMenu(!openSideMenu)}}>
                <Button className="px-0" variant={"link"}>
                    <Menu size={30}/>
                </Button>
            </SideMenuTrigger>
            <DialogPortal>
                <DialogOverlay />
                <SideMenuContent onInteractOutside={()=>{setOpenSideMenu && setOpenSideMenu(false)}} className="fixed z-50 gap-4 bg-background p-4 pr-4 shadow-lg transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500 inset-y-0 right-0 h-full w-10/12 border-r data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm">
                    <Close asChild onClick={()=> {setOpenSideMenu && setOpenSideMenu(false)}} className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
                        <X className="h-6 w-6" />
                    </Close>
                    <SideMenuHeader>
                        <DialogTitle>Menu</DialogTitle>
                    </SideMenuHeader>
                    {props.children}
                </SideMenuContent>
            </DialogPortal>
        </SideMenu>
    );
};