import type {FooterQuery} from 'storefrontapi.generated';
import { DCLogoTypes, Logo, Typography } from './ui';
import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react';
import { Link } from '@remix-run/react';
import { TikTokIcon } from '~/assets/icons';
import { cn } from '~/lib/utils';

export interface SocialNetworkUrlModel{
  facebook: string;
  tiktok: string;
  instagram: string;
  linkedin: string;
  sellerLogin: string;
}

export function Footer(
  {
    menu,
    socialNetwork
  }:{
    menu?: FooterQuery | any;
    socialNetwork?: SocialNetworkUrlModel;
  }) {


  const iconProps = {
    color:'rgb(20, 20, 20)',
    fill:'rgb(20, 20, 20)',
    size: 24,
    strokeWidth:1
  }
  const headings = '';
  const colLinks = 'text-white mb-2 md:w-fit md:!leading-4';

  let date = new Date();

  return (
    <footer className='bg-primary text-white pt-16 md:pt-8 px-nav'>
      <div className='grid grid-cols-12 gap-2 pb-10'>
        <div className='flex flex-col items-center md:items-start col-span-12 md:col-span-6 lg:col-span-5'>
          <Logo type={DCLogoTypes.BlueWhiteHorizontal} size={300} />
          <Typography variant="headline5" className='mt-8 md:mt-12'>Follow us</Typography>
          <div className='flex flex-row gap-4'>
            <Link to={socialNetwork!.facebook} target="_blank" className='rounded-full bg-white p-1.5' ><Facebook {...iconProps}/></Link>
            <Link to={socialNetwork!.tiktok} target="_blank" className='rounded-full bg-white p-1.5'>
              <TikTokIcon {...iconProps}></TikTokIcon>
            </Link>
            <Link to={socialNetwork!.linkedin} target="_blank" className='rounded-full bg-white p-1.5'><Linkedin {...iconProps}/></Link>
            <Link to={socialNetwork!.instagram} target="_blank" className='rounded-full bg-white p-1.5'><Instagram {...iconProps} fill='transparent' strokeWidth={2}/></Link>
          </div>
        </div>
        <div className='mt-16 md:mt-0 col-span-12 md:col-span-6 lg:col-span-7'>
          <div className='grid grid-cols-12 gap-x-4 gap-y-8 text-center md:text-left'>
            <div className='col-span-12 md:col-span-6 xl:col-span-3'>
              <Typography variant='headline5' className={headings}>Shop</Typography>
              <Link to='/#FeatureBrands' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>Feature Brands</Typography></Link>
              <Link to='/#LandingCategories' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>Categories</Typography></Link>
              {/* <Link to='/giftcards' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Gift Card</Typography></Link> */}
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Promotions</Typography></Link> */}
            </div>
            <div className='col-span-12 md:col-span-6 xl:col-span-3'>
              <Typography variant='headline5' className={headings}>Company</Typography>
              <Link to='/about' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>About us</Typography></Link>
              <Link to='/sustainability' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>Sustainability Commitment</Typography></Link>
              <Link to='/contact' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>Contact us</Typography></Link>
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Career</Typography></Link> */}
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Affiliate</Typography></Link> */}
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Location</Typography></Link> */}
            </div>
            <div className='col-span-12 md:col-span-6 xl:col-span-3'>
              <Typography variant='headline5' className={headings}>Seller</Typography>
              <Link to='/becomeaseller' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>How to Become a Seller</Typography></Link>
              <Link to={socialNetwork!.sellerLogin} className='md:block md:w-fit mb-3'><Typography variant="link" className={colLinks}>Request an Account</Typography></Link>
            </div>
            <div className='col-span-12 md:col-span-6 xl:col-span-3'>
              <Typography variant='headline5' className={headings}>Support</Typography>
              <Link to='/helpcenter' className='md:block md:w-fit' reloadDocument><Typography variant="link" className={colLinks}>Help Center</Typography> </Link>
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Track your order</Typography></Link> */}
              {/* <Link to='/' className='md:block md:w-fit'><Typography variant="link" className={colLinks}>Contact Us</Typography></Link> */}
              {/* <Link to='/faq' className='md:block md:w-fit mb-3' reloadDocument><Typography variant="link" className={colLinks}>FAQs</Typography></Link> */}
            </div>
          </div>
          <div className='mt-16 md:p-0 xs:p-0 sm:p-5'>
            {/* <Typography variant='headline5' className={cn(headings, "text-center md:text-left")}>Newsletter</Typography>
            <Typography variant='link' className='text-center md:text-left'>Subscribe and get 10% your first order</Typography>
            <div className='mt-5 flex flex-col gap-4 md:flex-row items-center'>
              <Input placeholder='Email address' className='bg-primary text-white m-0 flex-grow w-full md:w-auto'/>
              <Button variant='cool' className='w-full px-8 md:w-auto'><Typography variant="button">Subscribe</Typography></Button>
            </div> */}
          </div>
        </div>
      </div>
      <div className='flex flex-row justify-center gap-5 mb-2'>
        <Link to='/privacypolicy' className='md:block md:w-fit' reloadDocument><Typography variant="caption" className={cn("cursor-pointer",colLinks)}>Privacy Policy</Typography></Link>
        <Link to='/terms' className='md:block md:w-fit'><Typography variant="caption" className={cn("cursor-pointer",colLinks)}>Terms and Conditions</Typography></Link>
      </div>
      <Typography className='text-center mb-4 text-silver' type='p' variant='caption'>Frozen Logistics&#174; and Delivered Cold&#174; are registered trademarks of Frozen Logistics, LLC. &#169; {date.getFullYear()} All rights reserved.</Typography>    </footer>
  );
}
